import { CanActivateFn, Router } from "@angular/router";
import { inject } from "@angular/core";
import { GlobalStateService } from "../services/global-state.service";
import { NgToastService } from "ng-angular-popup";

export const authGuard : CanActivateFn = () => {
    const globalStateService = inject(GlobalStateService);
    const router = inject(Router);
    const toast = inject(NgToastService);
    if (globalStateService.isLoggedIn()) {
      return true;
    }
    toast.danger('You must be logged in to access this page');
    router.navigate(['auth/login']);
    return false;
};
