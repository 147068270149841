import { Component, EventEmitter, Input, Output, input, model, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-input-search',
  standalone: true,
  imports: [FormsModule, InputTextModule],
  templateUrl: './input-search.component.html',
  styleUrl: './input-search.component.scss'
})
export class InputSearchComponent {
  searchValue = model();
  handleSearchChange = output<string>();

  onSearchChange(event: string) {
    this.handleSearchChange.emit(event);
  }
}
