import { NgClass } from '@angular/common';
import { Component, effect, inject, input, signal } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { DividerModule } from 'primeng/divider';
import MENUS from '../../constants/sidebar-menu.constants';
import { LogoComponent } from '../logo/logo.component';
import { GlobalStateService } from '../../../shared/services/global-state.service';

@Component({
  selector: 'app-sidebar-content',
  standalone: true,
  imports: [
    NgClass,
    DividerModule,
    RouterLink,
    LogoComponent,
    RouterLinkActive,
  ],
  templateUrl: './sidebar-content.component.html',
  styleUrl: './sidebar-content.component.scss',
})
export class SidebarContentComponent {
  isVisible = input<boolean>(false);
  globalStateService = inject(GlobalStateService);
  isLoggedIn = this.globalStateService.isLoggedIn();
  menus = signal(MENUS);
  loggedInEffect = effect(
    () => {
      this.isLoggedIn = this.globalStateService.isLoggedIn();
      this.menus.set(this.filterMenus());
    },
    {
      allowSignalWrites: true,
    }
  );

  private filterMenus() {
    return MENUS.filter(menu => {
      if (menu.requiredLogin && !this.isLoggedIn) {
        return false;
      }
      if (menu.label === 'Login' && this.isLoggedIn) {
        return false;
      }
      return true;
    });
  }
}
