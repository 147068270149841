import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[toggleSidebar]',
  standalone: true
})
export class ToggleSidebarDirective {

  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter();

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: Event) {
    this.toggleSidebar.emit(true);
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event: Event) {
    this.toggleSidebar.emit(false);
  }

}
