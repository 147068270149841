<div class="flex align-items-center gap-3">
  <div class="hidden md:block">
    <app-input-search (handleSearchChange)="searchChange($event)" />
  </div>
  <app-theme-switcher />
  @if (globalStateService.isLoggedIn()) {
  <p-avatar class="cursor-pointer" size="large" (click)="menu.toggle($event)" [label]="globalStateService.userInfo().email.substring(0,2).toUpperCase()" shape="circle"></p-avatar>
  <p-menu #menu [model]="menuItems()" [popup]="true" styleClass="w-full md:w-15rem">
    <ng-template pTemplate="start">
      <button pRipple
        class="relative overflow-hidden w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
        <span class="inline-flex flex-column gap-2">
          @let user = globalStateService.userInfo();
          <span class="font-bold">{{user?.email}}</span>
          <p-tag icon="pi pi-user" styleClass="gradient" [value]="user?.role" />
        </span>
      </button>
    </ng-template>
    <ng-template pTemplate="submenuheader" let-item>
      <span class="text-primary font-bold">{{ item.label }}</span>
    </ng-template>
    <ng-template pTemplate="item" let-item>
      <a routerLink="{{ item.route }}" pRipple class="flex align-items-center p-menuitem-link">
        <span [class]="item.icon"></span>
        <span class="ml-2">{{ item.label }}</span>
      </a>
    </ng-template>
  </p-menu>
  }
</div>
