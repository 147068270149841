<div [ngClass]="{'show': isVisible(), 'hide': !isVisible()}" class="siderber-content">
  <div class="flex gap-2 align-items-center">
    <div routerLink="/project" class="cursor-pointer">
      <h3 class="gradient-text">Content Generator</h3>
      <p class="text-color-secondary text-xs">Helps you generate AI content faster, better & reliable</p>
    </div>

  </div>
  <p-divider type="solid"></p-divider>
  <div class="extra-menus my-3">
    <div class="list-none">
      @for (item of menus(); track $index) {
      <div [routerLink]="item.route" routerLinkActive="active" class="extra-menu flex gap-3 align-items-center">
        <i [class]="item.icon" class="pi text-color-secondary"></i>
        <span class="text-color-secondary">{{item.label}}</span>
      </div>
      }
    </div>
  </div>
</div>
