
<div class="pt-7">
  <div [ngClass]="{ 'flex': !isMobile, 'hidden': isMobile }" class="fixed z-5 surface-ground">
    <div class="sidebar" [class.p-2] = "!isMobile" (toggleSidebar)="toggleSidebar($event)" [style.width]="isSidebarOpen() ? '260px' : '15px'">
      <app-sidebar-content [isVisible]="isSidebarOpen()" />
    </div>
    <app-resizer (handleToggleBtn)="toggle()" />
  </div>
  <div [class.pl]="isSidebarOpen() && !isMobile">
    <ng-content></ng-content>
  </div>
</div>

