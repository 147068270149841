import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { Observable, catchError, throwError } from "rxjs";
import { StorageService } from "./services/storage.service";

export const authInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const storageService = inject(StorageService);
  const access_token = storageService.getItem('access_token');
  const clonedRequest = request.clone({
    setHeaders: {
      Authorization: `Bearer ${access_token}`
    }
  });
  return next(clonedRequest).pipe(
    catchError(errorData => {
      if (errorData.status == 401) {
        storageService.removeItem('access_token');
      }
      return throwError(() => errorData);
    })
  );
};
