import { NavigationComponent } from '../navigation/navigation.component';
import { Component } from '@angular/core';
import { LogoComponent } from '../logo/logo.component';
import { ProfileComponent } from '../profile/profile.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [LogoComponent, NavigationComponent, ProfileComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

}
