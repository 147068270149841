import { ROLE } from "./role.constants";

const Menus = [
  {
    label: 'Home',
    icon: 'pi-home',
    route: '/home',
  },
  {
    label: 'Dashboard',
    icon: 'pi-chart-line',
    route: '/dashboard',
  },
  {
    label: 'History',
    icon: 'pi-history',
    route: '/history',
    requiredLogin: true,
  },
  {
    label: 'Settings',
    icon: 'pi-cog',
    route: '/setting',
    requiredLogin: true,
  },
  {
    label: 'Login',
    icon: 'pi-sign-in',
    route: '/auth/login',
    requiredLogin: false,
  },

];

export default Menus;
