
import { MenuItem } from "primeng/api";

export const MENU_ITEMS: MenuItem[] = [
  {
    label: "Home",
    icon: "pi pi-home",
    shortcut: "⌘+H",
    route: "/home",
  },
  {
    label: "Dashboard",
    icon: "pi pi-chart-line",
    shortcut: "⌘+D",
    route: "/dashboard",
  },
  {
    label: "History",
    icon: "pi pi-history",
    shortcut: "⌘+H",
    route: "/history",
  },
  {
    label: "Settings",
    icon: "pi pi-cog",
    shortcut: "⌘+S",
    route: "/setting",
  },
];
